import ApiService from "@/core/services/api.service";

export default {
    // -----API TABLE WEEKLY REPORT------
    getPeriodeChickin(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly/periode-chickin", { params: credentials })
    },
    getPeriodeSetting(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/nasional/report/weekly/periode-setting", { params: credentials })
  },


    // -----API TABLE WEEKLY REPORT------
    getTableProduksiHE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/produksi-he", { params: credentials })
    },
    getTableProduksiTelur(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/produksi-telur", { params: credentials })
    },
    getTableProduksiDoc(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/produksi-doc", { params: credentials })
    },
    getTableStandingPopulasi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/stading-populasi", { params: credentials })
    },
    getTableHatchebility(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/hatchability", { params: credentials })
    },
    getTableFertility(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/fertility", { params: credentials })
    },
    getTableRerataHatchebility(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/rerata-hatchability", { params: credentials })
    },
    getTableRerataFertility(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/weekly-table/rerata-fertility", { params: credentials })
    },


    // -----API EXISTING POPULATION REPORT------
    getExistingPopulation(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/existing-population/by-population", { params: credentials })
    },


    // -----API STATUS INPUT REPORT------
    getStatusInputChickin(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/chickin", { params: credentials })
    },
    getStatusInputDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/deplesi", { params: credentials })
    },
    getStatusInputCollectingHE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/collecting-he", { params: credentials })
    },
    getStatusInputTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/transfer", { params: credentials })
    },
    getStatusInputFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/feed-in-take", { params: credentials })
    },
    getStatusInputAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/afkir", { params: credentials })
    },
    getStatusInputSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/setting-he", { params: credentials })
    },
    getStatusInputPullchick(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/pullchick", { params: credentials })
    },
    getStatusInputDistribusi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/report/status-input/distribusi", { params: credentials })
    },
}