<template>
    <div id="nasional-report-existing-populasi" data-app>
      <div class="gutter-b">
          <b-button v-b-toggle.collapse-filter variant="white" size="lg" class="bg-white font-weight-bolder w-100">Filtering Data</b-button>
          <b-collapse :visible="visible" id="collapse-filter">
              <ValidationObserver ref="formAdd">
              <b-form @submit="onSubmit" @reset="onReset">
                  <div class="card card-custom card-stretch">
                      <div class="card-body d-flex p-0 bg-white">
                          <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center">  
                              <div class="row w-100">
                                  <div class="col-12 col-sm-6">
                                      <validationProvider name="Farm" rules="required" v-slot="{ valid, errors }">
                                      <b-form-group
                                      id="input-group-2"
                                      label="Farm:"
                                      label-for="input-2"
                                      :state="valid"
                                      :invalid-feedback="errors[0]"
                                      class="m-0"
                                      >
                                          <b-form-select2
                                          id="input-2"
                                          v-model="filter.farm"
                                          variant="solid"
                                          size="lg"
                                          :options="opsFarm"
                                          placeholder="Silahkan pilih Farm"
                                          :state="valid"
                                          ></b-form-select2>
                                      </b-form-group>
                                      </validationProvider>
                                  </div>
                                  <div class="col-12 col-sm-6">
                                      <validationProvider :name="`Periode`" v-slot="{ errors, valid }">
                                      <b-form-group
                                      id="input-group-4"
                                      label="Periode:"
                                      label-for="input-4"
                                      class="m-0"
                                      :invalid-feedback="errors[0]"
                                      :state="valid"
                                      >
                                          <b-button 
                                          type="button"
                                          id="input-4"
                                          class="form-control form-control-solid text-left" 
                                          variant="outline-secondary" 
                                          size="lg"
                                          @click="modalDate = true">{{ showDate }}
                                          </b-button>
                                      </b-form-group>
                                      </validationProvider>
                                  </div>
                                  <template>
                                    <div class="col-12 col-sm-6">
                                      <validationProvider :name="`Populasi`" rules="required" v-slot="{ errors, valid }">
                                      <b-form-group 
                                      id="input-group-5" 
                                      label="Populasi:"
                                      label-for="input-5"
                                      :invalid-feedback="errors[0]"
                                      :state="valid">
                                          <treeselect
                                          id="input-5"
                                          :multiple="true"
                                          required
                                          :options="opsPopulasi"
                                          :normalizer="normalizer"
                                          :flatten-search-results="true"
                                          placeholder="Silahkan pilih Populasi"
                                          v-model="filter.populasi"
                                          />
                                      </b-form-group>
                                      </validationProvider>
                                    </div>
                                  </template>
                              </div>
                          </div>
                      </div>
                      <div class="card-footer">
                          <button  
                          type="submit"
                          ref="kt_submit"
                          class="btn btn-primary font-weight-bold"
                          >
                          Tampilkan
                          </button>
                      </div>
                  </div>
              </b-form>
              </ValidationObserver>
          </b-collapse>
      </div>
  
      <template v-if="show">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Recording Data
              </h3>
            </div>
          </div>

          <!--begin::Table-->
            <div class="card-body pt-0 pb-0">
              <v-data-table
                :headers="fields"
                :items="items"
                :items-per-page="items.length"
                hide-default-footer
                class="elevation-1"
                disable-sort
                :loading="isBusy"
                loading-text="Loading... Please wait"
              >
              </v-data-table>
            </div>
          <!--end::Table-->  
        </div>
      </template>
  
      <v-dialog
          ref="dialogDate"
          v-model="modalDate"
          persistent
          width="290px"
      >
          <v-date-picker
          v-model="dates"
          scrollable
          range
          color="bg-primary"
          header-color="bg-primary"
          >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="modalDate = false"
          >
              Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="setDate()"
          >
              OK
          </v-btn>
          </v-date-picker>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  
  import ReportService from "@/core/services/api/nasional/report.service";
  import PerusahaanService from "@/core/services/api/master/perusahaan.service";
  import ErrorService from "@/core/services/error.service";
  
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: "nasional-report-existing-populasi",
    components: {
        Treeselect
    },
    data() {
      return {
          show: true,
          modalDate: false,
          isBusy: true,
          dates: [],
          visible: true,
          filter: {
              farm: null,
              tgl_awal: null,
              tgl_akhir: null,
              populasi: []
          },
          curComponent: null,
          opsFarm: [
              { value: null, text: "Silahkan pilih Farm" },
          ],
          opsPopulasi: [],
          items: [],
          fields: [{ value: "woa", text: "WOA" }, { value: "deplesi", text: "Deplesi" }, {value: "feedintake", text: "Feed In Take"}, {value: "collecting", text: "Collecting HE"}, {value: "populasi", text: "Populasi"} ]
      }
    },
    computed: {
      ...mapGetters(["currentUser"]),
      showDate() {
          return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
      },
    },
    watch: {
      'filter.farm' (val, old) {
        this.filter.populasi = []
        if (val != old) {
            if (this.filter.tgl_awal && this.filter.tgl_akhir) {
                this.getPopulasi()
            }
        }
      },
    },
    methods: {      
      setDate() {
          if (this.dates.length < 1) {
              ErrorService.message({
                  action: 'Peringatan',
                  message: 'Silahkan pilih tanggal terlebih dahulu',
              })
              return;
          }
          if (this.dates.length < 2) {
              this.dates.push(this.dates[0])
          }
          this.dates.sort()
          this.filter.tgl_awal = this.dates[0]
          this.filter.tgl_akhir = this.dates[1]
          this.modalDate = false
          this.errorDate = false
          this.filter.populasi = []
          if (this.filter.farm) {
              this.getPopulasi()
          }
      },
      normalizer(node) {
        if (!node) return
        return {
          id: node.value,
          label: `${node.text} Chickin ${this.$options.filters.dateIndonesia(node.tanggal)}`,
          children: node.childrens,
        }
      },
      getFarm() {
        PerusahaanService.getPerusahaanFarm()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsFarm= [
                    { value: null, text: "Silahkan pilih Farm" },
                  ]
                  return;
              }

              this.opsFarm = res.data.data
              this.opsFarm.push({ value: null, text: "Silahkan pilih Farm" })
          })
          .catch((err) => {
            ErrorService.status(err)
            this.opsFarm= [
              { value: null, text: "Silahkan pilih Farm" },
            ]
          })
      },
      getPopulasi() {
          ReportService.getPeriodeChickin({ farm: this.filter.farm, tgl_awal: this.filter.tgl_awal, tgl_akhir: this.filter.tgl_akhir })
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsPopulasi = []
                  return;
              }
              this.opsPopulasi = res.data.data
          })
          .catch((err) => {
              this.opsPopulasi = []
              ErrorService.status(err)
          })
      },
      resetTable() {
        this.items = []
        this.fields = [{ value: "woa", text: "WOA" }, { value: "deplesi", text: "Deplesi" }, {value: "feedintake", text: "Feed In Take"}, {value: "collecting", text: "Collecting HE"}, {value: "populasi", text: "Populasi"} ]
      },
      getData () {
        ReportService.getExistingPopulation({ populasi: this.filter.populasi }).then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.resetTable()
                  this.isBusy = false
                  return;
              }
              this.items = res.data.data.data
              this.fields = res.data.data.fields
              this.isBusy = false
          })
          .catch((err) => {
            this.resetTable()
            this.isBusy = false
            return ErrorService.status(err)
          })
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      onSubmit(evt) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
          if (!success) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan lengkapi form data terlebih dahulu',
              })
              return;
          }

          if (!this.filter.populasi.length) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan pilih populasi terlebih dahulu',
              })
              return;
          }
          
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");

          this.isBusy = true
          this.items = []
          this.show = false
          this.$nextTick(() => {
            this.show = true
            this.getData()
          })
        
          // dummy delay
          setTimeout(() => {
              this.removeSpinner(submitButton)
              this.visible = true
              this.$nextTick(() => {
                  this.visible = false
              })
  
              window.scrollTo({
                  top: 100,
                  left: 0,
                  behavior: 'smooth'
              });
          }, 500);
        })
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        
          this.filter.farm= null
          this.filter.tgl_awal= null
          this.filter.tgl_awal= null
          this.filter.populasi= []
  
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Existing Population" }]);
      if (!this.currentUser.menu.statistik) {
        return this.$router.push({ name: 'dashboard'}).catch(()=>{})
      }
      this.getFarm()
    },
  };
  </script>